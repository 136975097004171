.mediakit-btn.mediakit-btn-primary.instagram-button:not(.transparent) {
    background-color: #4096FF;
}

.mediakit-btn.mediakit-btn-primary.instagram-button:not(:disabled):not(.mediakit-btn-disabled):not(.transparent):hover {
    background-color: rgb(24, 119, 242);
}

.mediakit-btn.mediakit-btn-primary.instagram-button.transparent {
    border: 1px solid #fff;
    background-color: transparent;
}

.mediakit-btn.mediakit-btn-primary.instagram-button.transparent:not(:disabled):not(.mediakit-btn-disabled):hover {
    background-color: rgba(0, 0, 0, .1);
}